/* HEADER */
.header {
  background-color: var(--off-black);
  width: 100%;
}

.header__container {
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background-color: var(--off-black);
  color: var(--pure-white);
  z-index: 100;
  transition: 0.65s transform var(--slide-up);
}

@media (min-width: 768px) {
  .header__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1200px) {
  .header__container {
    padding-left: 80px;
    padding-right: 80px;
  }
}

/* EARTHRISE INITIATIVE LOGO */
.header__logo {
  font-family: var(--heading-font);
  color: var(--pure-white);
}

@media (min-width: 768px) {
  .header__logo {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .header__logo {
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

/* "MENU" TOGGLE BUTTON */
.header__button {
  color: var(--pure-white);
  font-size: var(--base-font-size);
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}

.header__button:active,
.header__button:focus {
  outline-color: transparent;
  outline-style: none;
}

.header__button:hover {
  opacity: 0.87;
}

@media (min-width: 768px) {
  .header__button {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}

/* HEADER NAV */
.header__nav {
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  padding: 2rem 1.5rem 3rem 1.5rem;
  background-color: var(--off-white);
  transform: translateY(-200%);
  transition: transform 300ms ease-out;
  margin-top: 71px;
}

@media (min-width: 768px) {
  .header__nav {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1200px) {
  .header__nav {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.header__ul__group__title {
  font-family: var(--heading-font);
  font-feature-settings: var(--liga-setting);
  letter-spacing: 0.5px;
  font-size: 1rem;
  line-height: 28px;
  text-transform: uppercase;
  color: var(--orange);
  font-weight: 500;
  margin-bottom: 2rem;
}

.header__ul {
  margin: 0;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.header__li {
  font-size: 30px;
  line-height: 48px;
  padding-bottom: clamp(10px, 5vh, 48px);
}

.header__li:last-of-type {
  padding-bottom: 0;
}

.header__li a {
  color: var(--off-black);
  text-decoration: none;
}

.header__li a:hover,
.header__li a:active {
  cursor: pointer;
}

/* OPEN UTIL CLASS FOR HEADER ANIMATION */
.open {
  display: block !important;
  transform: translateY(0);
}
